import { useInView } from 'react-intersection-observer';
import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import HomepageBenefitsList from '../molecules/HomepageBenefitsList';

interface HomepageBenefitsSectionProps {
  className?: string;
  small?: boolean;
  swapped?: boolean;
  lead: string;
  title: string;
  body: string;
  background?: string;
  benefits: {
    title: string;
    description: string;
    cta?: {
      to: string;
      label: string;
    };
    image: {
      sourceUrl: string;
      altText: string;
    };
  }[];
  testimonial?: {
    logo: {
      sourceUrl: string;
      altText: string;
    };
    quote: string;
    author: {
      name: string;
      title: string;
      photo: {
        sourceUrl: string;
      };
    };
  };
}

const HomepageBenefitsSection: React.FC<HomepageBenefitsSectionProps> = ({
  className,
  small,
  swapped,
  lead,
  title,
  body,
  benefits,
  background,
  testimonial,
}) => {
  const { width } = useWindowSize();
  const backgroundImage =
    width < 768
      ? '/images/homepage-testimonial-mobile-bg.jpg'
      : '/images/homepage-testimonial-bg.jpg';


  return (
    <>
      <section className={`py-16 md:py-32 ${className}`}>
        <div className="u-container">
          <div className="max-w-2xl md:mb-12">
            <div className="u-s1 text-primary-red">{lead}</div>
            <h2 className="u-h2">{title}</h2>
            <p className="u-p1 text-body-copy">{body}</p>
          </div>
          <HomepageBenefitsList
            background={background}
            benefits={benefits}
            small={small}
            swapped={swapped}
          />
        </div>
      </section>

      {testimonial && (
        <section
          className="bg-left bg-cover"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="py-16 bg-left-bottom bg-no-repeat md:py-32">
            <div className="flex justify-end u-container">
              <div className="w-full md:pr-6 md:w-6/12 lg:w-7/12 md:pl-0">
                <div className="mb-8 md:mb-10">
                  <img
                    src={testimonial.logo.sourceUrl}
                    alt={testimonial.logo.altText}
                    className="w-auto h-5 md:h-7 lg:mx-0"
                  />
                </div>
                <p className="mb-8 text-2xl font-semibold leading-tight text-left text-white lg:text-2xl xl:text-4xl md:mb-13 xl:leading-12">
                  {testimonial.quote}
                </p>
                <div className="flex items-center text-left">
                  <div
                    className="flex-shrink-0 w-12 h-12 mr-6 bg-center bg-cover border-2 border-white rounded-full md:w-15 md:h-15 shadow-avatar"
                    style={{
                      backgroundImage: `url(${testimonial.author.photo.sourceUrl})`,
                    }}
                  />
                  <div className="text-sm md:text-base">
                    <div className="font-semibold text-white">
                      {testimonial.author.name}
                    </div>
                    <div className="text-white">{testimonial.author.title}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default HomepageBenefitsSection;
