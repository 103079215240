import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import HomepageBenefit from '../atoms/HomepageBenefit';
import Image from '../base/Image';

interface HomepageBenefitsListProps {
  background?:string;
  benefits: {
    title: string;
    description: string;
    cta?: {
      to: string;
      label: string;
    };
    image: {
      sourceUrl: string;
      altText: string;
    };
    transparentImage: {
      sourceUrl: string;
      altText: string;
    };
  }[];
  small?: boolean;
  swapped?: boolean;
}

const HomepageBenefitsList: React.FC<HomepageBenefitsListProps> = ({
  benefits,
  background,
  small,
  swapped,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const interval = useRef<NodeJS.Timeout>(null);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  useEffect(() => {
    if (interval.current || !inView) {
      return;
    }

    interval.current = setInterval(() => {
      setActiveIndex((index) => (index + 1) % benefits.length);
    }, 5000);

    setActiveIndex(0);

    return () => {
      clearInterval(interval.current);
    };
  }, [inView]);

  const overrideActive = (index: number) => {
    setActiveIndex(index);
    clearInterval(interval.current);

    interval.current = setInterval(() => {
      setActiveIndex((index) => (index + 1) % benefits.length);
    }, 5000);
  };

  return (
    <div
      ref={ref}
      className={`flex flex-col-reverse ${
        swapped ? 'lg:flex-row-reverse' : 'lg:flex-row'
      }`}
    >
      <motion.div className="flex flex-col justify-between w-full pt-12 space-y-8 md:py-5 lg:w-4/12" layout>
        {benefits.map((props, index) => (
          <HomepageBenefit
            key={index}
            active={index === activeIndex}
            small={small}
            onSelect={() => overrideActive(index)}
            {...props}
          />
        ))}
      </motion.div>
      <div
        className={`hidden relative lg:flex flex-col justify-start w-full lg:w-8/12 ${
          swapped ? 'lg:pr-10 xl:pr-20' : 'lg:pl-12 xl:pl-24'
        }`}
      >
        <div
          className="sticky -mr-5 homepage-benefit-image-container"
          style={{background}}
        >
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={activeIndex}
              variants={{ out: { opacity: 0 }, in: { opacity: 1 } }}
              initial="out"
              animate="in"
              exit="out"
              className="flex-auto w-full h-full max-h-full"
            >
              <Image
                data={benefits[activeIndex]?.transparentImage || benefits[activeIndex]?.image}
                className="w-full h-full"
                objectFit="contain"
              />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default HomepageBenefitsList;
