import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { chunk } from 'lodash';
import Image from '../base/Image';

const LogoStrip = ({ logos = [], height = '8rem' }) => {
  const [target, setTarget] = useState(1);
  const [chunks, setChunks] = useState([]);

  useEffect(() => {
    if (logos?.length <= 5) {
      return;
    }
    const timeout = setInterval(() => {
      const randTarget = Math.floor(Math.random() * (5 + 1) + 0);
      setTarget(randTarget);
    }, 5000);

    return () => {
      clearInterval(timeout);
    };
  }, []);

  useEffect(() => {
    let chunkSize = Math.ceil(logos.length / 5);
    if (chunkSize < 1) chunkSize = 1;

    setChunks(chunk(logos, chunkSize));
  }, [logos]);

  return (
    <div className="flex justify-between overflow-hidden" style={{ height }}>
      {chunks.map((logoChunk, i) => (
        <LogoRow
          target={target}
          key={`logo-row-${i}`}
          rowIndex={i}
          logos={logoChunk}
          height={height}
        />
      ))}
    </div>
  );
};

function LogoRow({ logos, rowIndex, height, target }) {
  const [slide, setSlide] = useState(0);
  const [translateY, setTranslateY] = useState(0);

  useEffect(() => {
    if (target === rowIndex) {
      if (slide < logos.length - 1) {
        setSlide(slide + 1);
      } else {
        setSlide(0);
      }
    }
  }, [target]);

  useEffect(() => {
    setTranslateY(slide * 8);
  }, [slide]);

  return (
    <motion.div
      animate={{ y: `-${translateY}rem` }}
      transition={{ duration: 0.5 }}
      className={`md:flex-auto w-1/3 ${rowIndex >= 3 && 'hidden md:block'}`}
    >
      {logos.map(({ image }, i) => (
        <div
          key={image.sourceUrl + '-' + rowIndex + '-' + i}
          className={`flex-auto w-auto`}
        >
          <div className="flex flex-col justify-center" style={{ height }}>
            <Image
              data={image}
              loading={`eager`}
              objectFit="contain"
              className={`w-full max-h-full transition-opacity ease-in-out ${
                i === slide
                  ? 'opacity-1 duration-1000'
                  : 'opacity-0 duration-500'
              }`}
            />
          </div>
        </div>
      ))}
    </motion.div>
  );
}
export default LogoStrip;
