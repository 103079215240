import Image from '../base/Image';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react';
import ArrowRight from './ArrowRight';
import FadeIn from '../molecules/FadeInWhenVisible';
import { useWindowSize } from 'react-use';

interface HomepageBenefitProps {
  title: string;
  description: string;
  cta?: {
    to: string;
    label: string;
  };
  active: boolean;
  small?: boolean;
  image?: any;
  onSelect: () => void;
}

const HomepageBenefit: React.FC<HomepageBenefitProps> = ({
  title,
  description,
  cta,
  active,
  small,
  image,
  onSelect,
}) => {
  const { width } = useWindowSize();
  const isMobile = width < 1023;

  return (
    <FadeIn>
      {image && (
        <div className="mb-0 md:mb-8 lg:hidden">
          <Image data={image} className="w-full h-auto" />
        </div>
      )}
      <div
        onClick={onSelect}
        className={`md:pr-10 md:pl-8 md:border-l-5 transition-all md:rounded-tr-md md:rounded-br-md ${
          active || isMobile
            ? 'pt-6 pb-6 md:shadow-lg border-primary-red'
            : 'border-transparent cursor-pointer md:opacity-30 pt-2 pb-0 hover:opacity-100'
        }`}
      >
        <h3
          className={`mb-3 font-semibold ${
            small ? 'text-2xl lg:text-lg' : 'text-xl xl:text-2xl'
          }`}
        >
          {title}
        </h3>
        <motion.div
          className="overflow-hidden"
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: active || isMobile ? 'auto' : 0, opacity: 1 }}
          transition={{ type: 'tween', ease: 'easeInOut', duration: 0.3 }}
        >
          <p key="paragraph" className="mb-3 leading-relaxed text-body-copy">
            {description}
          </p>
          {cta && (
            <Link
              key="link"
              to={cta.to}
              className="inline-flex items-center text-sm font-semibold transition text-primary-red hover:text-black focus:text-black"
            >
              <span className="mr-3">{cta.label}</span>{' '}
              <ArrowRight className="flex-shrink-0" />
            </Link>
          )}
        </motion.div>
      </div>
    </FadeIn>
  );
};

export default HomepageBenefit;
